import parse from "html-react-parser";
import { getFullContent } from "api/search";
import { notify } from "Components/Notification";
import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const MyArticleContent = props => {

    const isContentWithHighlights = props.isContentWithHighlights;

    const [isTextOverflow, setIsTextOverflow] = useState(false);
    const modeWithLongView = (isContentWithHighlights && isTextOverflow);

    const [modes, setModes] = useState(modeWithLongView ? ['ShortView', 'LongView', 'FullArticle'] : ['ShortView', 'FullArticle']);
    const initialMode = props.showFullContent ? modes[modes.length - 1] : modes[0];
    const [currentMode, setCurrentMode] = useState(initialMode);

    const [isFullContentLoading, setIsFullContentLoading] = useState(false);

    useEffect(() => {
        const updatedModes = modeWithLongView ? ['ShortView', 'LongView', 'FullArticle'] : ['ShortView', 'FullArticle'];
        setModes(updatedModes);
    }, [isTextOverflow, currentMode]);

    const [articleFullContent, setArticleFullContent] = useState(null);


    const requestFullArticle = (documentId) => {
        if (articleFullContent || !isContentWithHighlights) {
            return;
        }
        setIsFullContentLoading(true);
        getFullContent({
            searchId: documentId,
            advanceSearchKeyword: props.simpleSearchKeyword,
            advancedSearchObject: props.advancedSearchObject
        }).then(res => {
            setArticleFullContent(res.data.result.content);
        }).catch((err) => {
            console.log(err);
            notify.error("Failed to get full article");
            setCurrentMode(modeWithLongView ? 'LongView' : 'ShortView');
        }).finally(() => {
            setIsFullContentLoading(false);
        });

    };

    useEffect(() => {
        if (props.showFullContent) {
            if (currentMode !== modes[modes.length - 1]) {
                setCurrentMode(modes[modes.length - 1]);
            }
            requestFullArticle(props.documentId);
        }
        else {
            setCurrentMode(modes[0]);
        }
    }, [props.showFullContent]);

    // function copied from previous ResultCard component:
    const escapeHtmlExceptCertainTags = (htmlString) => {
        // First, escape all HTML to treat as text
        let escapedHtml = htmlString
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");

        // Define patterns for <em> and <br/> tags
        const allowedTagPatterns = {
            em: /&lt;(\/?em)&gt;/g,
            br: /&lt;br\s*\/?&gt;/g,
        };

        // Unescape <em> and <br/> tags
        escapedHtml = escapedHtml.replace(allowedTagPatterns.em, "<$1>");
        escapedHtml = escapedHtml.replace(allowedTagPatterns.br, "<br/>");

        return escapedHtml;
    };


    const textContainer = useRef();
    const textBlock = useRef();

    function handleResize() {
        if (currentMode === 'FullArticle') {
            return;
        }
        if (textContainer.current && textBlock.current) {
            const textContainerRect = textContainer.current.getBoundingClientRect();
            const textBlockRect = textBlock.current.getBoundingClientRect();

            const textHeightOverlap = textBlockRect.height - textContainerRect.height;

            const isTextOverflowing = textHeightOverlap > 0;

            setIsTextOverflow(isTextOverflowing);
        }

    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const isClipped = currentMode === modes[0];

    let viewModeToggleText = '';

    if (modeWithLongView) {
        switch (currentMode) {
            case modes[0]:
                viewModeToggleText = 'View More';
                break;
            case modes[1]:
                viewModeToggleText = 'View Full Article';
                break;
            case modes[2]:
                viewModeToggleText = 'View Less'
                break;
        }
    }
    else {
        switch (currentMode) {
            case modes[0]:
                viewModeToggleText = 'View Full Article';
                break;
            case modes[1]:
                viewModeToggleText = 'View Less'
                break;
        }
    }

    const style = { position: 'relative', overflow: 'hidden' };
    if (isClipped) {
        style.maxHeight = `${props.numberOfLines || 4}lh`;
    }

    const handleViewModeToggleClick = () => {
        const currentModeIndex = modes.indexOf(currentMode);
        let newModeIndex = (currentModeIndex + 1) % modes.length;

        const newMode = modes[newModeIndex];
        if (newMode == 'FullArticle') {
            requestFullArticle(props.documentId);
        }
        setCurrentMode(newMode);
        if (newMode != 'FullArticle') {
            handleResize();
        }
        if (newMode === modes[0]) {
            textContainer.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    useEffect(() => {
        if (currentMode == modes[0]) {
            handleResize();
        }
    }, [currentMode]);


    let contentToDisplay = (isContentWithHighlights && currentMode === 'FullArticle' && articleFullContent)
        ? articleFullContent : props.content;

    if (!contentToDisplay || !contentToDisplay.length) {
        return <p>Content not available</p>;
    }

    contentToDisplay = parse(escapeHtmlExceptCertainTags(contentToDisplay));

    let toggleDivStyle = {
        position: 'absolute',
        bottom: 0, right: 0,
        paddingLeft: '60px',
        maskImage: 'linear-gradient(to left, black 80%, transparent)',
    };

    if (currentMode == modes[modes.length - 1]) {
        toggleDivStyle = {
            position: 'relative',
            float: 'right'
        };
    }



    return (<div style={style} ref={textContainer}>
        <p ref={textBlock}>
            {contentToDisplay}
        </p>

        <div style={{
            ...toggleDivStyle,

            backgroundColor: 'rgba(255, 255, 255, 1)',
            zIndex: 1000,
            background: 'white',
            color: 'blue',
            paddingRight: '12px'
        }}>
            {isFullContentLoading ? <Spin indicator={antIcon} /> :
                <a onClick={() => handleViewModeToggleClick()} style={{ cursor: 'pointer', color: '#3c32f2' }}>
                    {viewModeToggleText}
                </a>}
        </div>

    </div>);

};