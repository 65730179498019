import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { addTagsResults, addTagModalToggle } from "Redux/Actions";
import ModalWindow from "Components/ModalWindow";
import TagBlock from "Components/TagBlock";
import CustomScrollbar from "Components/CustomScrollbar";
import { handleSaveHelper, handleTagAddHelper, handleTagRemoveHelper, tagInputHelper, tagSetHelper, tagsModalCloseHelper, sameArray } from "./Helper/tagHelper";

export const MyDisplayTagsModalComponent = (props) => {
  const { uids, uidsLast, allArticleList, setSearchLocalData, userTags, userTagsLast, append, clearSelection } = props.tag?.modal;
  const emptyTag = { value: "", type: "public" };
  const [tags, setTags] = useState([emptyTag]);
  const [tagInfoMessage, setTagInfoMessage] = useState([""]);
  useEffect(() => {
    //decide whether to show the previous tags in this modal when the update failed or not
    const showLastUserTags = (!userTags || userTags.length == 0) && props.tag?.error && sameArray(uids, uidsLast);
    const theTags = showLastUserTags ? userTagsLast : userTags;
    tagSetHelper({ setTags, userTags: theTags });
  }, [userTags]);

  useEffect(() => {
    if (!props.tag?.loading) {
      setTagInfoMessage([]);
    }
  }, [props.tag?.loading]);

  const handleCancel = () => {
    tagsModalCloseHelper({
      userTags,
      setTags,
      setTagInfoMessage,
    });
    props.addTagModalToggle({ visible: false });
  }
  const addTagsHandler = (payload) => {
    props.addTagsHandler(payload);
  }
  const handleSave = () => {
    handleSaveHelper({
      uids,
      allArticleList,
      setSearchLocalData,
      addTagsHandler,
      tagInfoMessage,
      tags,
      setTagInfoMessage,
      searchType: props.search.searchType,
      append,
      clearSelection,
    });
    props.addTagModalToggle({ visible: false });
  }
  const handleChange = ({ event, index, name }) =>
    tagInputHelper({
      event,
      index,
      name,
      tags,
      setTags,
      setTagInfoMessage,
      tagInfoMessage,
    });
  const handleTagAdd = () => handleTagAddHelper({ setTags, tags });
  const handleTagRemove = (index) =>
    handleTagRemoveHelper({
      setTags,
      tags,
      index,
      tagInfoMessage,
      setTagInfoMessage,
    });
  const handleTagEmptyAdd = () => setTags([emptyTag]);

  return (
    <ModalWindow
      open={true}
      title={"Add tags"}
      width={600}
      onOk={handleSave}
      onCancel={handleCancel}
      isNotClosable={true}
      okText={"Apply"}
      cancelText="Cancel"
      confirmLoading={props.tag?.loading}
    >
      <CustomScrollbar className="add-tags-scroller">
        <TagBlock
          tags={tags}
          handleTagAdd={handleTagAdd}
          handleChange={handleChange}
          tagInfoMessage={tagInfoMessage}
          handleTagRemove={handleTagRemove}
          keyEnter={handleSave}
          handleTagEmptyAdd={handleTagEmptyAdd}
        />
      </CustomScrollbar>
    </ModalWindow>
  );
}

const mapStateToProps = state => {
  return {
    ...state.Search,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addTagsHandler: (payload) => dispatch(addTagsResults(payload)),
    addTagModalToggle: (payload) => dispatch(addTagModalToggle(payload)),
  };
};

export const MyDisplayTagsModal = connect(mapStateToProps, mapDispatchToProps)(MyDisplayTagsModalComponent);
