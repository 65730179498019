import {
  GET_ALL_USER_SETTINGS_FAILED,
  GET_ALL_USER_SETTINGS_SUCCESS,
  GET_ALL_USER_SETTINGS_STARTED,
  CHANGE_DATE_FORMAT_STARTED,
  CHANGE_DATE_FORMAT_SUCCESS,
  CHANGE_DATE_FORMAT_FAILED,
  SET_CURRENT_PROFILE_STARTED,
  SET_CURRENT_PROFILE_SUCCESS,
  SET_CURRENT_PROFILE_FAILED,
  CHANGE_SORT_ORDER_SUCCESS,
  CHANGE_SORT_ORDER_STARTED,
  CHANGE_SORT_ORDER_FAILED,
  ADD_USER_DETAILS_FAILED,
  ADD_USER_DETAILS_STARTED,
  ADD_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_STARTED,
  UPDATE_USER_DETAILS_FAILED,
  DELETE_USER_DETAILS_STARTED,
  DELETE_USER_DETAILS_SUCCESS,
  DELETE_USER_DETAILS_FAILED
} from '../../Redux.constants';
import {
  getAllUserSettingsSuccess,
  getAllUserSettingsFailed,
  getAllUserSettingsStarted,
  changeDateFormatSuccess,
  changeDateFormatStarted,
  changeDateFormatFailed,
  setCurrentProfileStarted,
  setCurrentProfileSuccess,
  setCurrentProfileFailed,
  changesortOrderSuccess,
  changesortOrderStarted,
  changesortOrderFailed,
  addUserDetailsStarted,
  addUserDetailsSuccess,
  addUserDetailsFailed,
  updateUserDetailsStarted,
  updateUserDetailsSuccess,
  updateUserDetailsFailed,
  deleteUserDetailsStarted,
  deleteUserDetailsSuccess,
  deleteUserDetailsFailed,
} from './Helper';

import {INITIAL_STATE} from './initialState';

export default function Settings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SAVE_PROJECT_SETTINGS_CALLED':
      return {...state, userProjectSettings: {...state.userProjectSettings, listViewType: action.payload.listViewType}};

    case GET_ALL_USER_SETTINGS_SUCCESS:
      return getAllUserSettingsSuccess(state, action.payload);
    case GET_ALL_USER_SETTINGS_STARTED:
      return getAllUserSettingsStarted(state, action.payload);
    case GET_ALL_USER_SETTINGS_FAILED:
      return getAllUserSettingsFailed(state, action.payload);
    case CHANGE_DATE_FORMAT_SUCCESS:
      return changeDateFormatSuccess(state, action.payload);
    case CHANGE_DATE_FORMAT_STARTED:
      return changeDateFormatStarted(state, action.payload);
    case CHANGE_DATE_FORMAT_FAILED:
      return changeDateFormatFailed(state, action.payload);
    case SET_CURRENT_PROFILE_STARTED:
      return setCurrentProfileStarted(state, action.payload);
    case SET_CURRENT_PROFILE_SUCCESS:
      return setCurrentProfileSuccess(state, action.payload);
    case SET_CURRENT_PROFILE_FAILED:
      return setCurrentProfileFailed(state, action.payload);
    case CHANGE_SORT_ORDER_SUCCESS:
      return changesortOrderSuccess(state, action.payload);
    case CHANGE_SORT_ORDER_STARTED:
      return changesortOrderStarted(state, action.payload);
    case CHANGE_SORT_ORDER_FAILED:
      return changesortOrderFailed(state, action.payload);
    case ADD_USER_DETAILS_STARTED:
      return addUserDetailsStarted(state, action.payload);
    case ADD_USER_DETAILS_SUCCESS:
      return addUserDetailsSuccess(state,action.payload);
    case ADD_USER_DETAILS_FAILED:
      return addUserDetailsFailed(state,action.payload);
    case UPDATE_USER_DETAILS_STARTED:
      return updateUserDetailsStarted(state, action.payload);
    case UPDATE_USER_DETAILS_SUCCESS:
      return updateUserDetailsSuccess(state,action.payload);
    case UPDATE_USER_DETAILS_FAILED:
      return updateUserDetailsFailed(state,action.payload);
      case DELETE_USER_DETAILS_STARTED:
        return deleteUserDetailsStarted(state, action.payload);
      case DELETE_USER_DETAILS_SUCCESS:
        return deleteUserDetailsSuccess(state,action.payload);
      case DELETE_USER_DETAILS_FAILED:
        return deleteUserDetailsFailed(state,action.payload);
    default:
      return {...state};
  }
}
