import React from "react";

import {
  DATE_SETTINGS_LABEL,
  FILTER_CONSTANTS,
  HIDE_RESULTS_WITH_ZERO_COUNT,
} from "../../../../Constants/Settings/Settings.constants";
import { SelectElement } from "../../../../Components/SelectElement";
import ButtonElement from "../../../../Components/ButtonElement";

import style from "./style.module.scss";
import { Checkbox } from "antd";
import { LinesCountSettings } from "./LinesCountSettings";

function template(props) {
  return (
    <div className={style.preferencesWrapper}>
      <div className={style.preferenceSettings}>
        <ul className={style.preferenceSettings__list}>
          <li>
            <label> Select Date Format:</label>
            <SelectElement
              value={this.state.dateFormat}
              onChange={this.dateHandleChange}
              optionList={FILTER_CONSTANTS.DATE_FORMATS}
            />
          </li>
          <li>
            <label>{DATE_SETTINGS_LABEL}</label>
            <SelectElement
              value={this.state.sortOrder}
              onChange={this.sortOrderChange}
              optionList={FILTER_CONSTANTS.SORT_ORDER}
            />
          </li>
          <li>
            <Checkbox
              checked={this.state.hideResultsZeroCount}
              // defaultChecked={true}
              onChange={this.handleResultsZeroCount}
            >
              {HIDE_RESULTS_WITH_ZERO_COUNT}
            </Checkbox>
          </li>
          <li>
            <ButtonElement
              type={"primary"}
              children={"Update"}
              onClick={this.handleUpdate}
              loading={this.props.preferenceSettingLoading}
              disabled={this.state.disabled}
            />
          </li>
        </ul>

        <LinesCountSettings/>
      </div>
    </div>
  );
}

export default template;
