import { useRef, useState } from "react";
import globel from "styles/globel/style.module.scss";
import style from "./style.module.scss";
import TagFilter from "Components/TagFilter";
import { SEARCH_TYPE } from "constants";
import { MyTotalCount } from "./MyTotalCount";
import CustomScrollbar from "Components/CustomScrollbar";
import EmptyState from "Components/EmptyState";
import SearchCard from "Components/SearchCard";
import { SearchCardLoader } from "Components/SearchCard/SearchcardLoader";
import Pagination from "Components/Pagination";
import { MySearchCard } from "Components/SearchCard/MySearchCard";
import { connect } from "react-redux";
import { addTagsResults, setSearchData } from "Redux/Actions";
import ButtonElement from "Components/ButtonElement";
import { createAdvanceSearchQuery } from "Routes/Home/Helper";
import AlertElement from "Components/AlertElement";
import SVGIcons from "Components/SVGIcons";
import ErrorState from "Components/ErrorState";
import { ArticleBreadCrumb } from "./ArticleBreadCrumb";

const MySearchContentComponent = props => {

  console.log('MySearchContent-props', props)
  const [state, setState] = useState({
    showDeleteModal: false,
    sortedInfo: { columnKey: "", field: "timestamp", order: "descend" },
    selectedTitle: "",
    loading: false,
    selectedData: {},
    exportLoad: false,
    pagination: { pageNumber: 1, pageSize: 5 },
    showLoadMenu: false,
  });


  const arr = [...Array(15).keys()];
  const showSizeChanger= props.listViewType === "gridOne" && true;
  const pageSizeOptions = [50, 100, 200];
  let pageSize = props.search?.pageSize;
  if (showSizeChanger && !pageSizeOptions.includes(pageSize)) 
    pageSize = pageSizeOptions[0];

  let scrollElementWrapper = null; //useRef(); 

  const setScrollableElement = element => {
    // TODO: this seems to be broken 
    if (element && element !== scrollElementWrapper) {
      scrollElementWrapper = element?.parentElement?.parentElement;
    }
  };

  const needShowTagFilter = () => {
    // TagFilter is set of nested bubbles + Apply Filter/Clear All buttons
    // it is hidden when AdvancedSearch is on 
    const result = props.search.searchType !== SEARCH_TYPE.ADVANCED_SEARCH;
    return result;
  };

  const needShowAdanceSearchAlert = () => {
    // AdvanceSearchAlert is a banner with search query and (X) Close button
    let result = props.search.searchType === SEARCH_TYPE.ADVANCED_SEARCH;
    result = result && !props.dataLoadingTotalCountFromHomeState.loading;
    result = result && createAdvanceSearchQuery(props.search.advanceSearchPayloadData) != "";
    return result;
  };

  const handleSearchPagination = (pageNumber, pageSize) => {
    // this calls into MyHome.setSearchData which is dispatching stuff into redux 
    props.setSearchData({ pageNumber, pageSize });
    // scrollToTop(); TODO: implement this 
  };

  return (<>

    <div className={style.articleHeader}>
      {props.breadCrumbData?.length > 0 &&
        !props?.dataLoadingTotalCountFromHomeState.loading && (
          <div className={style.breadcrumbBlock}>
            <div className={style.breadcrumbBlock__label}>
              <p>Word cloud drill-down: </p>
            </div>
            <ArticleBreadCrumb
              crumbs={props.breadCrumbData}
              handleClick={props.handleBreadCrumbArticles}
              isClearable
              clearButtonText={"Back to default"}
            />
          </div>
        )}


      {needShowTagFilter() ?
        <TagFilter
          items={props.filterResults}
          handleDeleteTags={props.handleDeleteTags}
          handleDeleteMainTag={props.handleDeleteMainTag}
          handleDeleteDate={props.handleDeleteDate}
          handleDeleteAllTags={props.handleDeleteAllTags}
          handleApplyFilterButtonClick={props.handleApplyFilterButtonClick}
        />
        : null}

      {needShowAdanceSearchAlert() ?
        <div className={style.advancesearchQuery}>
          <div className={style.advancesearchQuery__item}>
            <AlertElement
              information={true}
              children={createAdvanceSearchQuery(
                props.search?.advanceSearchPayloadData
              )}
              icon={"SVG-infoIcon"}
            />
          </div>

          <a
            className={style.advancesearchQuery__button}
            onClick={() => {
              props.setSearchData({
                advanceSearchPayloadData: {},
                searchType: SEARCH_TYPE.NORMAL_SEARCH,
              });
            }}
          >
            <span>
              <SVGIcons type={"SVG-closeIcon"} />
            </span>
          </a>
        </div>
        : null}

      <div className={style.resultPagination}>
        {!props.search ||
          props?.dataLoadingTotalCountFromHomeState.loading ||
          props.dataLoadingTotalCountFromHomeState.totalCount == 0 ? (
          <></>
        ) : (
          <MyTotalCount
            totalCount={props.dataLoadingTotalCountFromHomeState.totalCount}
            data={props.dataLoadingTotalCountFromHomeState.data}
          />
        )}

        {props.dataLoadingTotalCountFromHomeState?.totalCount > 4 &&
          !props?.dataLoadingTotalCountFromHomeState.loading && (
            <Pagination
              current={props.search?.pageNumber}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              total={props.dataLoadingTotalCountFromHomeState?.totalCount}
              onChange={handleSearchPagination}
              showSizeChanger={showSizeChanger}
            />
          )}
      </div>
    </div>
    {props?.dataLoadingTotalCountFromHomeState.loading ? (
      <>
        <div className={style.resultContainer}>
          <CustomScrollbar className="result-view-scroller">
            <div
              className={style.resultInner}
              ref={setScrollableElement}
            >
              <div
                className={`${globel.resultBlock} ${globel[props.handleViewListClassName()]
                  }`}
              >
                {arr.map((item, index) => (
                  <div
                    className={globel.resultBlock__element}
                    key={index + item}
                  >
                    <SearchCardLoader />
                  </div>
                ))}
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </>
    ) : props?.search?.error ? (
      <ErrorState
        content="ERROR"
        type={"errorImage"}
        customHeight={"500"}
        errorMessage={props?.search?.errorMessage}
      />
    ) : props?.dataLoadingTotalCountFromHomeState?.data?.length === 0 ? (
      <EmptyState type="welcomeImage" content={"SEARCH_NO_DATA_FOUND"} />
    ) : (<>
      <div className={style.resultContainer}>
        <CustomScrollbar className="result-view-scroller">
          <div className={style.resultInner} ref={setScrollableElement}>
            <div className={`${globel.resultBlock} ${globel[props.handleViewListClassName()]}`}>
              {props.dataLoadingTotalCountFromHomeState?.data?.filter(d => d !== undefined).map((data, i) => {
                return (
                  <div className={globel.resultBlock__element} key={data.id || data.DocumentId || i}>
                    <MySearchCard
                      // {...this.props}
                      setSearchLocalData={props.setSearchLocalData}
                      listViewType={props.listViewType}
                      metaDataList={props.metaDataList} // from state
                      loading={props.loading}
                      handleFullContentData={props.handleFullContentData}
                      data={data}
                      addTagsHandler={props.addTagsHandler}
                      tagState={props.tag}
                      selectedClassname={props.handleViewListClassName()}
                      search={props.search}
                      dateFormat={props.dateFormat}
                      allArticleList={props.dataLoadingTotalCountFromHomeState?.data}
                      searchType={props.search?.searchType}
                      currentTabType={props.tab}
                      expandAllCard={props.expandAllCard}
                      expandAllTags={props.expandAllTags}
                      handleExpandCardActive={props.handleExpandCardActive}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </CustomScrollbar>
      </div>
    </>)
    }
  </>);
};

const mapStateToProps = state => {
  return {
    ...state.Search,
    ...state.Tags,
    dateFormat: state.Settings.format.dateFormat,
    metaDataList: state.FilterFields?.allFieldsWithMetadata
      ?.filter((item) => item?.dataType === 5)
      ?.map((item) => item?.name),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTagsHandler: (params) => dispatch(addTagsResults(params)),
    handleDeleteSaved: (params, callBack) =>
      dispatch(deleteSavedResults(params, callBack)),
    // setSearchData: (params) => dispatch(setSearchData(params)),
  };
};

export const MySearchContent = connect(mapStateToProps, mapDispatchToProps)(MySearchContentComponent);
