import { remove } from "lodash";
import { notify } from "../../../../Components/Notification";
import { DASHBOARD_CONSTANTS } from "../../../../Constants/Dashboard/Dashboard.constants";
import { ROUTES } from "../../../../Constants/Routes/Routes.constants";
import { removeDuplicates } from "../../../../Redux/Actions/FilterOptions/helper";
import {
  createSaveDashboardPayload,
  handleInsightsDataHelper,
  updateDashboardController,
} from "./Helper/helper";

export const handleEditInsightHelper = ({ chartname, insightList }) => {
  let newObject = {};
  insightList.map((item) => {
    if (item.insightsName == chartname) {
      newObject = {
        chartInfo: item.insightsContentbyte,
        insightId: item.id,
        insightName: item.insightsName,
        dataSet: item.insightsContentbyte.dataSet,
      };
    }
  });
  return newObject;
};

export const removeItemHelper = ({ item, state }) => {
  let newInsightData = state.state.insightsData;
  newInsightData.splice(item, 1);
  let testData = state.state.data;
  testData.splice(item, 1);
  let testConfig = state.state.config;
  testConfig.splice(item, 1);
  let testChartNames = state.state.chartNames;
  testChartNames.splice(item, 1);
  let testLabels = state.state.labels;
  testLabels.splice(item, 1);
  let testItems = state.state.items;
  testItems.splice(state.state.items.length - 1, 1);
  let testSelectedInsights = state.state.selectedInsights;
  testSelectedInsights.splice(item, 1);
  let testLayout = state.state.layout;
  testLayout.pop();
  state.setState({
    data: testData,
    chartNames: testChartNames,
    config: testConfig,
    labels: testLabels,
    items: testItems,
    selectedInsights: testSelectedInsights,
    layout: testLayout,
    isEdit: false,
    onViewEdit: true,
    insightData: newInsightData,
  });
  handleImportButtonDisable({ state, testSelectedInsights });
};

const handleImportButtonDisable = ({ state, testSelectedInsights }) => {
  state.props.history.replace({
    pathname: ROUTES.DASHBOARD,
    state: {
      selectedList: testSelectedInsights,
    },
  });
  if (testSelectedInsights.length == 3) {
    state.setState({ importInsightDisabled: true });
  } else {
    state.setState({ importInsightDisabled: false });
  }
};

export const handleSaveHelper = ({ state }) => {
  state.setState({ saveLoading: true });
  let insightList = [];
  state.props.insightsList.insightData.map((item) => {
    if (state.state.selectedInsights.includes(item.id)) {
      insightList.push(item);
    }
  });
  state.props.saveDashboard(
    createSaveDashboardPayload({
      state,
      insightList,
      insightPresentId: state.state.selectedInsights,
    }),
    ({ error, message }) => {
      if (error) {
        state.setState({
          saveLoading: false,
          saveError: message,
        });
      } else {
        state.setState({
          saveModalWindow: false,
          dashboardName: "",
          saveLoading: false,
          saveError: "",
        });
        notify.success(
          DASHBOARD_CONSTANTS.DASHBOARD_SAVED_SUCCESSFULLY_MESSAGE,
          true
        );
      }
    },
    () => {
      // state.props.getDashboardList({});
    }
  );
};
export const editDashboardMountHelper = ({
  importInsights,
  location,
  handleImportSuccess,
  setState,
}) => {
  setState({ dataLoading: true });
  importInsights({
    payload: location.state.payload.map((item) => item.id),
    handleImportSuccess,
  });
  setState({
    dashboardName: location.state.dashboardName,
    layout: location.state.layout,
    isEdit: true,
  });
};
export const viewDashboardMountHelper = ({
  importInsights,
  location,
  handleImportSuccess,
  setState,
}) => {
  setState({ dataLoading: true });
  importInsights({
    payload: location.state.payload,
    handleImportSuccess,
  });
  setState({
    dashboardName: location.state.dashboardName,
    dashboardId: location.state.dashboardId,
    isView: true,
    layout: location.state.layout,
    isFromUrl: false,
    insightsData: location.state.payload?.map((item) => ({
      id: item,
    })),
    selectedInsights: location.state.payload,
  });
};

export const handleSelectEditDashboardSelectList = (data) => {
  let selectedList = [];
  data.map((item) => {
    selectedList.push(item.id);
  });
  return selectedList;
};

export const handleSelectEditDashboard = (data) => {
  let selectedList = [];
  data.map((item) => {
    selectedList.push(item.id);
  });
  return selectedList;
};

export const createDashboardMountHelper = ({
  selectedList,
  importInsights,
  setState,
}) => {
  setState({ dataLoading: true });
  let layoutItems = [];
  let data = [];

  if (selectedList.length > 0) {
    selectedList.map((item, index) => {
      let newLayout = {
        i: index,
        x: (index * 6) % 12,
        y: 0,
        w: 6,
        h: 4,
        minW: 5,
        minH: 3,
        maxW: 20,
        maxH: 10,
      };
      layoutItems.push(newLayout);
      data.push(index);
    });
  }
  let insightsData = handleInsightsDataHelper({
    selectedValues: selectedList,
  });
  importInsights({
    payload: selectedList,
    handleImportSuccess: ({
      newData,
      chartNames,
      config,
      insightsName,
      error,
    }) => {
      if (error) return setState({ dataLoading: false });

      setState({
        dataLoading: false,
        data: newData,
        chartNames: chartNames,
        config,
        labels: insightsName,
        selectedInsights: selectedList,
        layout: layoutItems,
        items: data,
        insightsData: insightsData,
      });
    },
  });
};

export const handleUpdateHelper = ({ state }) => {
  updateDashboardController({ state });
};
export const handleChangeSelectedValuesHelper = ({ state, selectedValues, updateableValues }) => {
  //remove old selected items if it's now unselected
  let unselected = state.state.selectedInsights.filter(i => updateableValues.includes(i) && state.state.selectedInsights?.includes(i) && !selectedValues.includes(i));
  let newArray = state.state.selectedInsights.filter(i=>!unselected.includes(i));
  //add newly selected items
  newArray = removeDuplicates([...newArray, ...selectedValues]);
  if (newArray.length > 3) {
    state.setState({
      insightInfoMessage: DASHBOARD_CONSTANTS.DASHBOARD_MAXIMUM_ALLOWED_ERROR,
    });
  } else {
    
    if (state.props.location.state?.isEdit && newArray.length > 3) {
      state.setState({
        insightInfoMessage:
          DASHBOARD_CONSTANTS.DASHBOARD_MAXIMUM_IMPORT_ERROR_ON_SAVE,
      });
    } else {
      state.setState({
        selectedInsights: newArray,
        onViewEdit: true,
        insightInfoMessage: "",
      });
      let insightListArray = [];
      if (newArray.length == 0) {
        state.setState({ insightsData: [], insightInfoMessage: "" });
      }
      selectedValues.map((insightItem) => {
        state.props.insightsList.allInsightsList.map((item) => {
          if (insightItem == item.id) {
            insightListArray.push(item); //To  push the insights which are selected
            state.setState({
              insightsData: insightListArray,
              insightInfoMessage: "",
            });
          }
        });
      });
    }
  }
};

export const sameArray = (array1, array2) => {
  if (!array1 && !array2) return true;
  if (!array1 || !array2) return false;
  array1 = removeDuplicates(array1);
  array2 = removeDuplicates(array2);
  if (array1.length != array2.length) return false;
  return array1.sort().join(',') === array2.sort().join(",");
};