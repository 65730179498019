import { notify } from "../../Components/Notification";
import { message } from "antd";

export const updateUserInfo = ({ userRecord, state }) => {
  let updateUserPayload;
  updateUserPayload = {
    isActive: userRecord.isActive,
    emailid: userRecord.emailid,
    userProfile: state.userRole == "" ? userRecord.userProfile : state.userRole,
    firstname: state.firstName == "" ? userRecord.firstname : state.firstName,
    lastname: state.lastName == "" ? userRecord.lastname : state.lastName,
  };
  return updateUserPayload;
};

export function findUserRole(value) {
  switch (value) {
    case 1:
      return "admin";
    case 2:
      return "analyst";
    case 4:
      return "user";
    default:
      return "analyst";
  }
}

export function validateUserDetails(values) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!values.emailid || values.emailid.length === 0) {
    return { message: "Please enter the email ID", valid: false };
  } else if (!values.emailid.match(validRegex)) {
    return { message: "Please enter a valid email", valid: false };
  } else if (!values.firstname ||values.firstname.length === 0) {
    return { message: "Please enter the first name", valid: false };
  } else if (!values.lastname ||values.lastname.length === 0) {
    return { message: "Please enter the last name", valid: false };
  } else if (!values.groupId) {
    return { message: "Please select a user group", valid: false };
  } else {
    return { message: "", valid: true };
  }
}

/**
 * Function return name of user used for notifying
 * if user have firstname and lastname returns both as concatenated
 * if user have only firstname or lastname returns it
 * if user don't have firstname or lastname return the email id as name of user
 * @param {{firstname: string, lastname: string, emailid: string}} userDetails
 * @returns {string}
 */
function getUserName(userDetails) {
  let { firstname, lastname, emailid } = userDetails;
  let name = firstname
    ? firstname && lastname
      ? firstname + " " + lastname
      : firstname
    : lastname
    ? lastname
    : "";
  name = name || emailid;
  return name;
}
