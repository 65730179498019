import React from 'react';
import SVGIcons from '../../Components/SVGIcons';
import TooltipContainer from '../../Components/Tooltip';
import globel from '../../styles/globel/style.module.scss';
import style from './style.module.scss';

function UserActions(props) {
  return (
    <ul className={globel.tableActions}>
      {props.canUpdate ?
        (<li>
          <TooltipContainer title={'Edit User'} mouseLeaveDelay={0} placement='bottom'>
            <span onClick={(e) => { props.handleUpdate(props.record) }}>
              <SVGIcons type={'SVG-edit'} />
            </span>
          </TooltipContainer>
        </li>) : null}
      {props.canDelete ?
        (<li>
          <TooltipContainer title={'Delete User'} mouseLeaveDelay={0} placement='bottom'>
            <span onClick={(e) => { props.handleDelete(props.record) }}>
              <SVGIcons type={'SVG-delete'} />
            </span>
          </TooltipContainer>
        </li>) : null}
    </ul>
  );
}

export default UserActions;
