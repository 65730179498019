import React from "react";

import InputField from "../../../Components/InputField";
import Loader from "../../../Components/Loader";
import ModalWindow from "../../../Components/ModalWindow";

import style from "./style.module.scss";
import { DEFAULT_USER_GROUP } from "../../../constants";
export function EditUserGroupModal(props) {
  return (
    <ModalWindow
      title={props.title}
      isNotClosable={true}
      okText={props.buttonTitle}
      cancelText={"Cancel"}
      onCancel={(e) => {
        props.onCancel();
      }}
      confirmLoading={props.loader}
      onOk={(e) => {
        props.onSubmit();
      }}
      open={props.visible}
      isNonMaskable={true}

    >
      <ul>
        <li>
          Unique Name:
          <InputField
            placeholder={"Group Name"}
            onChange={(e) => {
              props.onChange("name", e.target.value);
            }}
            value={props.group.name}
          />
        </li>

        {props.defaultSearchVisible &&
          (<li>
            Default Search:
            <InputField
              inputType={"selectElement"}
              optionList={props.savedSearches?.filter(q=>q.groupId===props.group?.id).map(s => ({ label: s.searchKeyword, value: s.id }))}
              value={props.group.defaultQueryId}
              onChange={(e) => {
                props.onChange("defaultQueryId", e);
              }}
            />
          </li>)
        }
        <li>
          Note:
          <InputField
            placeholder={"Note"}
            value={props.group.note}
            onChange={(e) => {
              props.onChange("note", e.target.value);
            }}
          />
        </li>
      </ul>
    </ModalWindow>
  );
}
