import React from 'react';

import SystemSettings from "./Children/SystemSettings";

class Settings extends React.Component {
  state = {
    tab: 'Users',
  };

  componentDidMount() {}
  componentDidUpdate() {}

  onTabChange = (value) => {
    this.setState({tab: value});
  };

  render() {
    return <SystemSettings/>;
  }
}

export default Settings;
