import parse from "html-react-parser";
import { connect, useSelector } from "react-redux";

import { Card } from "../../../Components/Card";
import ButtonBlock from "../Children/buttonBlock";
import TooltipContainer from "../../Tooltip";
import { MetadataBlock } from "./MetadataBlock";
import { handleDateFormatter } from "../Helper";
import {
  removeEMTag,
  arrayToString,
  getCardTitle,
  getDatePublished,
} from "../../../utils/dataFormatter";

import SVGIcons from "../../SVGIcons";

import global from "../../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import { handleWebsiteDomain } from "../../../utils/commonUtils";
import { useMemo } from "react";
import { Checkbox } from 'antd';
import {documentSelectionChangeAction , tagSelectionChangeAction} from '../../../Redux/Reducers/Home/HomeState';
import { MyArticleContent } from "./MyArticleContent";

const DisplayResultCardComponent = props => {
  const allFieldsWithDatatype = useSelector(
    (state) => state.FilterFields.allFieldsWithMetadata
  );
  const cardTitle = getCardTitle(props.data, allFieldsWithDatatype);
  const checked = props.selectedDocuments.indexOf(props.data?.DocumentId)!==-1;

  const onCheckboxChange = (e)=>{
    props.documentSelectionChange({documentIds:[props.data?.DocumentId], checked: e.target.checked, userTags:props.data?.user_tags});
  };

  const handleTagSelection = (tag)=>{
    props.tagSelectionChange({tag})
  };


  const searchWord = useSelector((state) => state.Search.search.value);
  const advancedSearchObject = useSelector(state=>state.Search?.search.advanceSearchPayloadData);

  const isAdvancedSearch = useMemo(()=>{
    const result = advancedSearchObject && Object.keys(advancedSearchObject).length;
    return !!result;
  }, [advancedSearchObject]);

  const normalizeContent = contentAsStringOrArray=>{
    if (Array.isArray(contentAsStringOrArray)){
      const result = contentAsStringOrArray.reduce((acc, curr) => acc + "..." + curr + "...<br />", "");
      return result;
    }
    else{
      return contentAsStringOrArray;
    }
  };

  const normalizedContent = normalizeContent(props.data.Content);

  const settings = useSelector(state=>state.Settings.userProjectSettings);

  let numberOfLines = 4;
  if (settings.viewLineCountSettings){
    let { lineCount_2x2, lineCount_3x4, lineCount_ListView } = settings.viewLineCountSettings;
    if (!lineCount_2x2){
      lineCount_2x2 = 5;
    }
    if (!lineCount_3x4){
      lineCount_3x4 = 4;
    }
    if (!lineCount_ListView){
      lineCount_ListView= 4;
    }

    if (props.listViewType == 'gridTwo'){
      numberOfLines = lineCount_2x2;
    }
    if (props.listViewType == 'gridThree'){
      numberOfLines = lineCount_3x4;
    }
    if (props.listViewType == 'gridOne'){
      numberOfLines = lineCount_ListView;
    }

  }

  return (
    <Card className={global.resultCard}>
      <div className={global.resultCard__header}>
         <div className={global.resultCard__header__titleCheckbox}>
            <TooltipContainer title={removeEMTag(cardTitle || "")} placement="bottom">
              <h3>
                {parse(cardTitle ||"Title not available")}
              </h3>
            </TooltipContainer>
            <TooltipContainer title="Select this artcile" placement="bottom" >
              <Checkbox checked = {checked} onChange={onCheckboxChange}>
              </Checkbox>
            </TooltipContainer>
          </div>
        <div className={global.headerBottom}>
          <div className={global.headerBottom__searchcontent}>
            <SearchCardContent
              websiteDomain={handleWebsiteDomain(props.data || {})}
              datePublished={getDatePublished(
                props.data,
                allFieldsWithDatatype
              )}
              dateFormat={props.dateFormat}
            />
          </div>
          <div className={global.headerBottom__controlsicon}>
            <ul className={global.controlsList}>
              <ButtonBlock
                handleTagsList={props.handleTagsList}
                showPreview={props.setShowPreview}
                article={props.data}
              />
            </ul>
          </div>
        </div>
      </div>
      <div className={global.resultCard__content}>
        {props.ListItems?.length > 0 && (
          <div className={global.tagsBlock}>
            <ul className={global.tagList}>
              <UserTagsView tags={props.ListItems} handleTagSelection={handleTagSelection} selectedTags = {props.selectedTags}  />
            </ul>
          </div>
        )}

        <div className={global.articleText}>
          <MyArticleContent
            numberOfLines={numberOfLines} 
            content={normalizedContent}
            isContentWithHighlights={isAdvancedSearch || (!!searchWord)}
            showFullContent={props.expandAllCard}
            documentId={props.data.DocumentId}
            
            // properties for loading full content: 
            simpleSearchKeyword={searchWord}
            advancedSearchObject={isAdvancedSearch ? advancedSearchObject : null}            
          />
        </div>

        <div className={global.resultMetatag}>
          <MetadataBlock
            metaDataList={props.metaDataList}
            data={props.data}
            currentTabType={props.currentTabType}
            expandAllTags={props.expandAllTags}
          />
        </div>
      </div>
    </Card>
  );
}

function SearchCardContent({ websiteDomain, datePublished, dateFormat }) {
  let filepath = websiteDomain?.testFile ? true : false;
  let url = websiteDomain?.url ? websiteDomain.url : websiteDomain;
  return (
    <ul className={global.searchitemList}>
      <li>
        <TooltipContainer
          title={filepath ? "File path" : "Website domain"}
          placement={"left"}
        >
          <span>
            {filepath ? (
              <SVGIcons type="SVG-folder" />
            ) : (
              <SVGIcons type="SVG-websource" />
            )}
          </span>
        </TooltipContainer>
        <span>{parse(arrayToString(url) || "Website is not available")}</span>
      </li>
      <li>
        <TooltipContainer title={"Publish date"} placement="bottom">
          <span>
            <SVGIcons type="SVG-publisheddate" />
          </span>
        </TooltipContainer>
        <span>{handleDateFormatter(datePublished, dateFormat)}</span>
      </li>
    </ul>
  );
}

/**
 *
 * @param {{tags: string[]}} param
 * @returns {JSX.Element}
 */
function UserTagsView({ tags, handleTagSelection, selectedTags }) {
  return (
    <>
      {tags.map((item, i) => (
        <li key={i + item}>
          <div className={style.userTagElement}>
            <span>
              <SVGIcons type="SVG-tags" />
            </span>
            <span
              onClick={() => handleTagSelection(item)}
              className={selectedTags?.includes(item) ? style.selectedTag  : style.normalTag}
              style={{ cursor: 'pointer' }} 
            >{parse(item)}</span>
          </div>
        </li>
      ))}
    </>
  );
}


const mapStateToProps = state => {
  const fromState = {
      selectedDocuments: state.Home.selectedDocuments,
      selectedTags: state.Home.selectedTags,
  };
  return fromState;
};

const mapDispatchToProps = dispatch => {
  return {
      documentSelectionChange: (payload) => dispatch(documentSelectionChangeAction(payload)),
      tagSelectionChange: (payload) => dispatch(tagSelectionChangeAction(payload)),
  };
};

const DisplayResultCard = connect(mapStateToProps, mapDispatchToProps)(DisplayResultCardComponent);
export default DisplayResultCard;