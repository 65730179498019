/* eslint-disable max-params */
import React from "react";

import template from "./template";
import { KEYS } from "../../dataKeys";
import {
  deleteModalHandlerHelper,
  handleDownloadDashboardHelper,
  handleEditDashboardHelper,
  handleViewDashboardHelper,
} from "./Helper";
import { ROUTES } from "../../Constants/Routes/Routes.constants";
import { DASHBOARD_CONSTANTS } from "../../Constants/Dashboard/Dashboard.constants";
import { DEFAULT_PAGE_COUNT } from "../../constants";
import { getInsightsList } from "../../api/insights";
import { errorMEssageHandler } from "../../utils/ErrorHandler";
import { handleApiError } from "../../utils/commonUtils";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  state = {
    showDeleteModal: false,
    selectedData: {},
    showAddModal: false,
    pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT },
    selectedInsights: [],
    insightsData: [],
    importLoading: false,
    deleteLoading: false,
    searchValue: "",
    insightInfoMessage: "",
    sortedInfo: {
      columnKey: "updatedDate",
      field: "updatedDate",
      order: "descend",
    },
    showCheckbox: false,
    displayDashboards: false,
    listLoading: true,
    fullInsightData: [],
    totalSize: 0,
    startPage: 1,
    insightPageSize: 10,
    getInsightError: { error: false, errorMessage: "" },
    showSavedDashboard: false,
    loadMoreLoading: false,
    loadDashboardLoading: false,
  };

  componentDidMount() {
    this.props.getInsightData({});
    this.props.loadFilterFields();
    this.getInsightData();
  }
  getDashboardList() {
    let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
    let payload = {
      emailId: email,
      pageSize: this.state.pagination.pageSize,
      pageNumber: this.state.pagination.pageNumber,
    };
    this.props.getDashboardData(payload);
  }

  /*Select which all insights are selected from the modal windwo*/
  handleChange = (selectedValues) => {
    if (selectedValues.length > 3) {
      this.setState({
        insightInfoMessage: DASHBOARD_CONSTANTS.DASHBOARD_MAXIMUM_ALLOWED_ERROR,
      });
    } else {
      this.setState({ selectedInsights: selectedValues });
      let insightListArray = [];
      selectedValues.map((insightItem) => {
        insightListArray.push({ id: insightItem });
        this.setState({
          insightsData: insightListArray,
          insightInfoMessage: "",
        });
      });
    }
  };
  componentDidUpdate() {
    if (this.props?.location?.state?.clear || !this.props.location.state) {
      this.setState({ displayDashboards: false });
      this.props.history.replace({
        pathname: ROUTES.DASHBOARD,
        state: {},
      });
    }
  }

  handleDelete = (record) =>
    this.setState({ showDeleteModal: true, selectedData: record });

  deleteModalHandler = (value, event) => {
    if (event == "modalOff") {
      this.setState({
        showDeleteModal: false,
        deleteLoading: false,
      });
    }
  };
  addModalHandlerCancel = (value, event) => {
    if (event == "modalOff") {
      this.setState({
        showAddModal: false,
        insightInfoMessage: "",
        searchValue: "",
      });
    }
  };
  handleImportSuccess = () => {
    this.props.history.push(ROUTES.DASHBOARD);
    this.setState({
      showAddModal: false,
      searchValue: "",
    });
  };

  addModalHandlerOk = () => {
    if (this.state.selectedInsights.length == 0) {
      this.setState({
        insightInfoMessage: DASHBOARD_CONSTANTS.EMPTY_INSIGHT_SELECTED_ERROR,
      });
    } else {
      this.props.history.push({
        pathname: ROUTES.DASHBOARD,
        state: { selectedList: this.state.selectedInsights },
      });
      this.setState({
        importLoading: true,
        showAddModal: false,
        displayDashboards: true,
        importLoading: false,
        selectedInsights: [],
      });
    }
  };

  addDashboardModal = () =>
    this.setState({ showAddModal: true, importLoading: false });

  deleteModalHandlerYes = () => deleteModalHandlerHelper({ state: this });

  handleEditDashboard = (record) =>
    handleEditDashboardHelper({ state: this, record });

  handleDownloadDashboard = (record) =>
    handleDownloadDashboardHelper({ state: this, record });

  /*change route to view dashboard selected*/
  handleViewDashboard = (record) =>
    handleViewDashboardHelper({ state: this, record });

  handleDashboardPagination = (pageNumber, pageSize) => {
    this.setState(
      { pagination: { pageNumber: pageNumber, pageSize: pageSize } },
      () => this.getDashboardList()
    );
  };

  handleSearchChange = (event) =>
    this.setState({ searchValue: event.target.value, showCheckbox: true });

  searchHandler = (data) => {
    return data.filter((item) =>
      item.name.toUpperCase().includes(this.state.searchValue.toUpperCase())
    );
  };
  handleButtonClicks = (type, state) => {
    this.props.history.push({
      pathname: ROUTES.DASHBOARD,
      state: { clickType: type, ...this.state },
    });
  };

  getInsightData = async () => {
    try {
      this.setState({ listLoading: true });
      let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
      let payload = {
        emailId: email,
        pageNumber: this.state.startPage,
        pageSize: this.state.insightPageSize,
      };
      let { data } = await getInsightsList(payload);
      this.setState({
        fullInsightData: data.result.result,
        listLoading: false,
        totalSize: data.result.resultHits,
      });
    } catch (error) {
      handleApiError(error, () =>
        this.setState({
          getInsightError: {
            error: true,
            errorMessage: errorMEssageHandler(error?.response?.status),
          },
        })
      );
    }
  };
  //====================================

  handleSavedModal = (value) => {
    this.props.history.push({
      pathname: ROUTES.DASHBOARD,
      state: {
        isView: false,
        isEdit: false,
      },
    });
    this.getDashboardList();
    this.setState({ showSavedDashboard: value });
  };

  loadMore = async () => {
    if (this.state.fullInsightData.length == this.state.totalSize) {
    } else {
      this.setState({ loadMoreLoading: true });
      let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
      let payload = {
        emailId: email,
        pageNumber: 1,
        pageSize: this.state.insightPageSize + 10,
      };
      this.setState({ insightPageSize: this.state.insightPageSize + 10 });
      let { data } = await getInsightsList(payload);
      this.setState({
        fullInsightData: data.result.result,
        listLoading: false,
        totalSize: data.result.resultHits,
        loadMoreLoading: false,
      });
    }
  };

  render() {
    return template.call(this);
  }
}
export default Dashboard;
