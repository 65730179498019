import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TooltipContainer from "../Tooltip";
import { SearchInput } from "../SearchInput";
import CustomScrollbar from "../CustomScrollbar";

import cn from "./CustomCheckboxGroup.module.scss";
import { ContentLoader } from "Components/ContentLoader";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


/** @typedef {{value: string; label: string}} IItem */
/**
 * @typedef {{options: IItem[]; selectedOptions: string[]; onOptionsChange: (selectedOptions: string[]) => void
 * isSearchVisible: boolean, searchPlaceHolder: string}} ICustomCheckboxGroupProps
 */

/**
 * @param {ICustomCheckboxGroupProps} param
 * @returns {JSX.Element}
 */
export function CustomCheckboxGroup(props) {
  const {
    options,
    selectedOptions,
    searchPlaceHolder,
    isSearchVisible,
    onOptionsChange,
    loading = false,
  } = props;

  const [itemsToShow, setItemsToShow] = useState(
    [...options]
  );
  const [searchKey, setSearchKey] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    const formattedSearchKey = searchKey.trim().toLowerCase();
    if (formattedSearchKey !== "") {
      const searchedOptions = options.filter((option) =>
        option.label.toLowerCase().includes(formattedSearchKey)
      );
      setSearchResult(searchedOptions);
      setItemsToShow(
        [...searchedOptions]
      );
    } else {
      setSearchResult([]);
      setItemsToShow([...options]);
    }
  }, [searchKey, options]);

  const onSelectionChange = (value) => {
    const itemIndex = selectedOptions.findIndex((item) => item === value);
    const newList = [...selectedOptions];
    if (itemIndex === -1) newList.push(value);
    else newList.splice(itemIndex, 1);
    onOptionsChange(newList);
  };

  /** @param {React.ChangeEvent<HTMLInputElement>} e */
  const onSearchChange = (e) => {
    const searchKey = e.target.value;
    setSearchKey(searchKey || "");
  };

  const handleSelectAll = ()=>{
    // passing searchedOptions that will need to be selected

    const formattedSearchKey = searchKey.trim().toLowerCase();
    if (formattedSearchKey !== "") {
      props.handleSelectAll(searchResult);
    } else {
      props.handleSelectAll([...options]);
    }
  };

  const handleClearAll = ()=>{
    // clearAll works for all options
    setSearchKey("");
    props.handleClearAll();
  };

  return (
    <>
      <div className={cn.customeCheckcontainer}>
        {isSearchVisible && ( <>
          <SearchInput
            value={searchKey}
            placeholder={searchPlaceHolder}
            handleChange={onSearchChange}
          />
          {props.showSelectAll ? 
          <div style={{
            marginTop: '-10px', 
            display: 'flex'
          }}>
      
            {/* here are the controls to select all  */}
            <div>
                <Button type="link" size="small" onClick={handleSelectAll}>Select All</Button>
            </div>      
            <div>
              <Button type="link" size="small" onClick={handleClearAll}>Clear All</Button>
            </div>
          </div> : null}
          </>
        )}
        {loading ? (
          <>
            <CustomScrollbar className="inner-scroller">
              <>
                <ContentLoader height={2} width={100} spaceBetween={0.375} />
                <ContentLoader height={2} width={100} spaceBetween={0.375} />
                <ContentLoader height={2} width={100} spaceBetween={0.375} />
                <ContentLoader height={2} width={100} spaceBetween={0.375} />
              </>
            </CustomScrollbar>
          </>
        ) : (
          <CustomScrollbar className="inner-scroller">
            <ul className={cn.checkboxListbox}>
              {itemsToShow.length > 0 ? (
                <>
                  {itemsToShow.map((item, index) => (
                    <li key={String(index) + item.value}>
                      <CheckboxInput
                        label={item.label}
                        value={item.value}
                        checked={selectedOptions.includes(item.value)}
                        onSelectionChange={onSelectionChange}
                        count={item.count}
                      />
                    </li>
                  ))}
                </>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </ul>
          </CustomScrollbar>
        )}
      </div>
    </>
  );
}

CustomCheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  searchPlaceHolder: PropTypes.string,
  isSearchVisible: PropTypes.bool,
  onOptionsChange: PropTypes.func,
};

CustomCheckboxGroup.defaultProps = {
  selectedOptions: [],
  searchPlaceHolder: "Search options",
  isSearchVisible: true,
};

/** @param {{label: string, value: string, checked: boolean, onSelectionChange: (string) => void}} props */
function CheckboxInput({ label, value, checked, onSelectionChange, count }) {
  /** @param {React.ChangeEvent<HTMLInputElement>} e */
  const toggleCheck = (e) => onSelectionChange(e.target.value);

  return (
    <div className={cn.checkboxListpane}>
      <span className={cn.checkboxItem}>
        <input
          type="checkbox"
          id={value}
          name={label}
          value={value}
          checked={checked}
          onChange={toggleCheck}
        />
      </span>
      <p>
        <TooltipContainer
          title={label.length > 22 ? label : ""}
          placement={"top"}
          htmlFor={value}
        >
          <span>{label}</span>
        </TooltipContainer>
      </p>
      <span className={cn.countPane}>({count})</span>
    </div>
  );
}
