import { connect } from "react-redux";
import global from "../../styles/globel/style.module.scss";
import { useEffect, useState } from "react";
import { ALL_ARTICLE_TAB } from "Constants/Home/Home.constants";
import { MyFilterSection } from "Components/MyFilter/MyFilterSection/MyFilterSection";
import { MyHeaderSection } from "Components/HeaderSection/MyHeaderSection";
import { handleExportAsCsv } from "./components/SearchControls/helper";
import { MySearchTab } from "./components/SearchTabElements/MySearchTab";
import { formatSearchPayload, getFilteredValues, handleDateFilterSearchHelper, handleDateFilterSearchHelperUntilNow, handleDeleteMainTagHelper, handleDeleteTagHelper, handleFilterReformatter, handleSaveHelper, homeMountHelper, postMessageHandler, searchApiHandler, formatBreadCrumbData } from "./Helper";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { addSaveSearchResult, ClearFilterFieldData, deleteSavedResults, FilterFields, getSavedSearchList, setSearchData, updateUserGroupAction } from "Redux/Actions";
import { DEFAULT_VIEW_TYPE } from "constants";
import { DEFAULT_DATE_FILTER_TYPE } from "constants";
import { DEFAULT_NUMBER_OF_DAYS } from "constants";
import { COMMUNICATION_KEYS } from "constants";
import { DATE_FILTER_KEY, INSIGHT_OPENING_MESSAGE } from "./constants";
import ModalWindow from "Components/ModalWindow";
import { DEFAULT_DATE_FILTER_VALUE } from "constants";
import { getTabNameFromSearchCriteria } from "Components/HeaderSection/HomeHeader/helper";
import { ROUTES } from "Constants/Routes/Routes.constants";
import { formatDate } from "utils/commonUtils";
import moment from 'moment';
import { RefreshSearchComplete } from "Redux/Reducers/Home/HomeState";
import { createSaveListViewTypeAction } from "Redux/Actions/UserProjectSettings";
import { getDefaultQuery } from "../../api/userGroups";

const MyHomeComponent = props => {

    const { FilterFields } = props;
    const handleSelectAll = ({ name, items }) => {

        const newFilterValues = items.map(x => x.value);

        props.setSearchData(
            {
                filter: {
                    ...props.search.filter,
                    [name]: newFilterValues
                }
            }
        );

        setLocalState({
            filterResults: { ...state.filterResults, [name]: newFilterValues }
        });
    };

    const handleClearAll = ({ name }) => {
        const newFilter = { ...props.search.filter };
        delete newFilter[name];

        props.setSearchData(
            {
                filter: newFilter
            }
        );

        const newFilterResults = { ...state.filterResults };
        delete newFilterResults[name];

        setLocalState({
            filterResults: newFilterResults
        });

        if (Object.keys(newFilter).length == 0) {
            setLocalState({ forceRunSearch: true });
        }
    };

    const handleChangeCheckBox = ({ name, selectedValues, items }) => {
        const newList = state.filterResults[name] ?? [];
        const appliedFilter = getFilteredValues(newList, items);
        const appendedList = [...appliedFilter, ...selectedValues];

        const newFilter = { ...props.search.filter };
        if (!appendedList.length) {
            delete newFilter[name];
        }
        else {
            newFilter[name] = appendedList;
        }

        props.setSearchData({
            filter: newFilter
        });


        const newFilterResults = { ...state.filterResults };
        if (!appendedList.length) {
            delete newFilterResults[name];
        }
        else {
            newFilterResults[name] = appendedList;
        }


        setLocalState({
            filterResults: newFilterResults,
        });

    };

    const handleChangefilterDateType = (type) => {
        setLocalState({ filterDateType: type });
        if (
            state.filterResults[DATE_FILTER_KEY]?.from &&
            state.filterResults[DATE_FILTER_KEY]?.to
        ) {
            handleDeleteMainTagHelper({
                setSearchData: props.setSearchData,
                state: state,
                setState: setLocalState,
                tag: DATE_FILTER_KEY,
            });
        }
    };

    const handleDeleteTags = (removedItem, tag) => {
        handleDeleteTagHelper({
            state: state,
            setState: setLocalState,
            search: props.search,
            setSearchData: props.setSearchData,
            removedItem,
            tag,
        });
    };

    const handleDeleteMainTag = (tag) => {
        if (tag.toLowerCase().includes("date")) {
            setLocalState({
                filterResults: {},
                filterDateType: 1,
                numberOfDays: DEFAULT_NUMBER_OF_DAYS,
            });
        }
        handleDeleteMainTagHelper({
            state: state,
            setSearchData: props.setSearchData,
            setState: setLocalState,
            tag,
        });
    };

    const handleDeleteAllTags = () => {
        props.setSearchData({
            filter: {},
            dateFilter: DEFAULT_DATE_FILTER_VALUE,
        });
        setLocalState({ filterResults: {}, numberOfDays: 10, forceRunSearch: true });
    };

    const handleViewListClassName = () => {
        switch (state.listViewType) {
            case "":
                return "";
            case "gridTwo":
                return "gridTwo";
            case "gridThree":
                return "gridThree";
        }
    };

    const handleListView = (value) => {
        setLocalState({ listViewType: value });
    };

    const handleLastNDaysChange = (value, name) => {
        setLocalState({
            filterResults: { ...state.filterResults, [DATE_FILTER_KEY]: { from: value.from, to: value.to } }
        });
    };

    const handleUntilNowChange = (value, name) => {
        if (value == null) {
            let newTag = state.filterResults[DATE_FILTER_KEY];
            delete newTag[DATE_FILTER_KEY];
            setLocalState({
                filterResults: {
                    ...state.filterResults,
                    [DATE_FILTER_KEY]: newTag,
                },
            });
        }
        else {
            setLocalState({
                filterResults: {
                    ...state.filterResults,
                    [DATE_FILTER_KEY]: {
                        from: formatDate(value),
                        to: formatDate(moment(new Date())),
                    },
                },
            });
        }
    };

    const setDateSearchValue = (e) => {
        if (e == null) {
            props.setSearchData({
                dateFilter: {
                    key: DATE_FILTER_KEY,
                    value: { to: "", from: "" },
                },
            });
        }
        else {
            props.setSearchData({
                dateFilter: {
                    key: DATE_FILTER_KEY,
                    value: {
                        ...props.search.dateFilter.value,
                        to: formatDate(e.to),
                        from: formatDate(e.from),
                    },
                },
            });
        }
    };


    const handleChangeDate = ({ e, name, type, numberOfDays }) => {
        if (type == "lastNDays") {
            setLocalState({ filterDateType: 3, numberOfDays });
            handleLastNDaysChange(e, name);
            handleDateFilterSearchHelper({
                setSearchData: props.setSearchData,
                e,
            });
        }
        else if (type == "untilNow") {
            setLocalState({ filterDateType: 2 });
            handleUntilNowChange(e, name);
            handleDateFilterSearchHelperUntilNow({
                setSearchData: props.setSearchData,
                e,
            });
        } else {
            setDateSearchValue(e);
            if (e == null) {
                handleDeleteMainTagHelper({
                    state: state,
                    setState: setLocalState,
                    setSearchData: props.setSearchData,
                    tag: DATE_FILTER_KEY,
                });
            } else {
                setLocalState({
                    filterResults: {
                        ...state.filterResults,
                        [DATE_FILTER_KEY]: {
                            from: formatDate(e?.from),
                            to: formatDate(e?.to),
                        },
                    },
                });
            }
        }
    };

    const handleApplyFilterButtonClick = () => {
        handlePostMessage();

        const payload = formatSearchPayload(props.search);
        payload.projectId = props.mainFrameState.state.currentProject;
        searchApiHandler({
            payload: payload,
            setState: setLocalState,
            setSearchData: props.setSearchData,
            projectSwitching: props.mainFrameState.state.showProjectSwitchingModal
        });

        props.setSearchData({
            filter: state.filterResults
        });

    };

    const [state, setState] = useState({
        forceRunSearch: false,
        filterResults: {},
        savedSearches: [],
        listViewType: (props.userProjectSettings && props.userProjectSettings.listViewType) || DEFAULT_VIEW_TYPE,
        filterDateType: DEFAULT_DATE_FILTER_TYPE,
        numberOfDays: DEFAULT_NUMBER_OF_DAYS,
        showSaveModal: false,
        showLoadMenu: false,
        tab: ALL_ARTICLE_TAB,
        expandAllCard: false,
        expandAllTags: false,
        showViewInNewTab: false,
        breadCrumbData: [],
        exportLoading: false,
        notInteractive: false,
        isInsightModalOpen: false, data: [],
        loading: false,
        totalCount: 0,
        defaultQuery: null,
    });

    const handleLoad = (value) => {
        setLocalState({ showLoadMenu: value });
    };

    const handleExport = () => {
        setLocalState({ exportLoading: true });

        handleExportHelper(props.search, () => {
            setLocalState({ exportLoading: false })
        });
    };

    const handleExportHelper = (search, setLoaderOff) => {
        let newPayload = { ...formatSearchPayload(search) };
        delete newPayload.pageNumber;
        delete newPayload.pageSize;
        handleExportAsCsv(newPayload, setLoaderOff);
    };

    const channel = new BroadcastChannel("selectedList");

    const setLocalState = data => {
        setState(prevState => {
            return { ...prevState, ...data };
        });
    };

    const history = useHistory();
    const location = useLocation();

    const handlePostMessage = () => {
        console.log('handlePostMessage-props.search:', state, props.search);
        if (!state.insightStarted) return;
        postMessageHandler({ channel: channel, data: props.search });
    };

    useEffect(() => {
        handlePostMessage();
    }, [state.insightStarted])

    const handleSave = () => setLocalState({ showSaveModal: true });
    const handleModal = () => setLocalState({ showSaveModal: false });

    const loadDefaultQuery = () => {
        if (!state.defaultQuery) return;
        const data = state.defaultQuery.searchHistory.metadata;
        const json = JSON.parse(data);
        const tab = getTabNameFromSearchCriteria(json?.searchCriterias);
        props.setSearchData({ searchTab: tab, ...json });
        setLocalState({ forceRunSearch: true });
    };

    useEffect(async () => {
        loadDefaultQuery();
    }, [state.defaultQuery]);

    useEffect(async () => {
        const defaultQuery = await getDefaultQuery({ projectId: props.mainFrameState.state.currentProject });
        setLocalState({ defaultQuery: defaultQuery.data, forceRunSearch: true});
    }, [props.userProfile, props.mainFrameState.state.currentProject]);

    useEffect(() => {
        const payload = {
            setState: setLocalState,
            channel: channel,
            handleStayActive: () => { },
            history: history,
            location: location,
            search: props.search,
            setSearchData: props.setSearchData,
            FilterFields: props.FilterFields,
            loadFilterFields: props.loadFilterFields,
            handleGetDashboardPreview: () => { }
        };
        homeMountHelper(payload);

        return () => {
            channel.postMessage({ type: COMMUNICATION_KEYS.PARENT_TERMINATE });
            channel.close();
        };
    }, []);

    useEffect(() => {
        if (props.search.error) {
            return;
        }

        handlePostMessage();

        const payload = formatSearchPayload(props.search);
        payload.projectId = props.mainFrameState.state.currentProject;

        let mounted = true;
        const performUpdate = () => {
            const updateLocalState = (payload) => {
                if (!mounted) return;
                setLocalState(payload);
            }

            searchApiHandler({
                payload: payload,
                setState: updateLocalState,
                setSearchData: props.setSearchData,
                projectSwitching: props.mainFrameState.state.showProjectSwitchingModal,
            });

            updateLocalState({
                tab: getTabNameFromSearchCriteria(props.search.searchCriterias),
            });

            if (state.forceRunSearch) {
                updateLocalState({ forceRunSearch: false });
            }

            if (props.forceRunSearchFromRedux) {
                props.refreshComplete();
            }

            if (props.search?.searchWords) {
                updateLocalState({
                    breadCrumbData: props.search.searchWords.map((item, index) => ({
                        label: item,
                        value: index,
                    })),
                });
            }
        }

        performUpdate();

        return () => {
            mounted = false;
        };
    },
        [
            props?.search?.pageNumber,
            props?.search?.pageSize,
            props?.search?.sortorder,
            props?.search?.sortby,
            state.filter,
            state.forceRunSearch,
            props.forceRunSearchFromRedux
        ]);


    useEffect(() => {
        setLocalState({
            filterResults: handleFilterReformatter(props.search),
        });
    }, [props.search.filter]);

    useEffect(() => {
        const value = state.listViewType;
        const pageSize = value === "gridTwo" ? 4 : value === "gridThree" ? 12 : 50;
        if (props.currentProject) {
            props.saveListView(value);
        }
        const pageNumber = 1;
        props.setSearchData({ pageSize, pageNumber });
    }, [state.listViewType]);


    const handleExpandAll = () => {
        setLocalState({ expandAllCard: !state.expandAllCard });
    };

    const handleExpandAllTags = () => {
        setLocalState({ expandAllTags: !state.expandAllTags });
    };

    const handleViewNewTab = () => {
        setLocalState({ isInsightModalOpen: true });
    };

    const handleInsightOpenModalOnOk = () => {
        if (props.history.location.pathname == "/home") {
            let pathname = window.location.href;
            let newUrl = pathname.split("/");
            newUrl[newUrl.length - 1] = "insights";
            let updatedUrl = newUrl.join("/");
            window.open(updatedUrl + "?from=" + "newtab");
            props.history.push({
                pathame: ROUTES.HOME,
                search: `?page=homePage`,
            });
        }
        setLocalState({ showViewInNewTab: true });
        setLocalState({ isInsightModalOpen: false });
    };

    const handleInsightOpenModalOnClose = () => {
        setLocalState({ isInsightModalOpen: false });
    };


    const handleSearchOnTabChange = ({ searchCriteria, searchTab }) => {
        props.setSearchData({
            searchCriterias: searchCriteria ? [searchCriteria] : [],
            searchTab,
        });

        setLocalState({ forceRunSearch: true });
    };

    const onTabChange = tab => {
        if (state.loading) {
            return;
        }

        setLocalState({ expandAllCard: false });
        const envARTICLE_EXTERNAL_REPORT_KEY = 'File System';
        const fileSystemSource = env.ARTICLE_EXTERNAL_REPORT_KEY?.split(",")?.filter((item) => item && item);

        switch (tab) {
            case "News":
                handleSearchOnTabChange({
                    searchCriteria: {
                        include: false,
                        field: "Source Type",
                        values: fileSystemSource,
                    },
                    searchTab: tab,
                });
                return;
            case "External Reports":
                handleSearchOnTabChange({
                    searchCriteria: {
                        include: true,
                        field: "Source Type",
                        values: fileSystemSource,
                    },
                    searchTab: tab,
                });
                return;
            default:
                handleSearchOnTabChange({ searchTab: tab });
                return;
        }
    };

    const handleBreadCrumbArticles = (event) => {
        if (event.clear) {
            setLocalState({ breadCrumbData: [] });
            props.setSearchData({ searchWords: [] });
            return;
        }
        let breadCrumbData = state.breadCrumbData.slice(0, event?.value + 1);
        setLocalState({ breadCrumbData });
        props.setSearchData({
            searchWords: formatBreadCrumbData(breadCrumbData),
        });
    };
    
    const setDefaultQuery = (defaultQuery) => {
        setLocalState({ defaultQuery });
    };

    const propsForSearchTab = {
        handleViewListClassName: handleViewListClassName,
        filterResults: state.filterResults,
        handleDeleteTags: () => { },
        handleDeleteMainTag: handleDeleteMainTag,
        handleDeleteDate: () => { },
        listViewType: state.listViewType,
        breadCrumbData: state.breadCrumbData,
        handleBreadCrumbArticles: (event) => { handleBreadCrumbArticles(event); },
        handleApplyFilterButtonClick: handleApplyFilterButtonClick,

        handleDeleteAllTags: handleDeleteAllTags,
        handleDeleteTags: handleDeleteTags,

        dataLoadingTotalCountFromHomeState: {
            data: state.data,
            loading: state.loading,
            totalCount: state.totalCount,
        },

        tab: state.tab,
        onTabChange: onTabChange,
        listViewType: state.listViewType,
        handleExpandAll: handleExpandAll,
        expandAllCard: state.expandAllCard,

        handleExpandAllTags: handleExpandAllTags,
        expandAllTags: state.expandAllTags,

        handleListView: handleListView,


        setSearchData: props.setSearchData,
        setSearchLocalData: (data) => {
            setLocalState({ data });
        },
        handleDeleteSaved: props.handleDeleteSaved,
        search: props.search,
        handleSavedSearchPaginate: props.handleSavedSearchPaginate,
        tag: props.tag,
        selectedDocuments: props.selectedDocuments,
        selectedTags: props.selectedTags,
        userTags: props.userTags,
    };

    return (
        <div className={`${global.layoutBlock} ${props.mainFrameState.state.filterVisible.visible ? global[props.mainFrameState.state.filterVisible.className] : ""} `}>
            <div className={global.pageAside}>
                <MyFilterSection
                    loading={FilterFields.loading}
                    filter={props.search.filter}
                    dateFilter={props.search.dateFilter}
                    error={props.FilterFields.error}
                    errorMessage={props.FilterFields.errorMessage}
                    handleSelectAll={handleSelectAll}
                    handleClearAll={handleClearAll}
                    handleChangeCheckBox={handleChangeCheckBox}
                    handleChangeDate={handleChangeDate}
                    searchTab={state.tab}
                    searchData={props.searchData}

                    numberOfDays={state.numberOfDays}
                    handleChangefilterDateType={handleChangefilterDateType}
                    filterDateType={state.filterDateType}

                />
            </div>


            <div className={global.pageContent}>
                <div className={global.resultWrapper}>
                    <MyHeaderSection
                        exportLoading={state.exportLoading}
                        handleLoad={handleLoad}
                        handleExport={handleExport}
                        handleSave={handleSave}
                        handleModal={handleModal}
                        showLoadMenu={state.showLoadMenu}
                        showSaveModal={state.showSaveModal}

                        handleFilterVisible={props.mainFrameState.handleFilterVisible}
                        handleViewNewTab={handleViewNewTab}
                        filterResults={state.filterResults}
                        handleSavedSearchPaginate={props.handleSavedSearchPaginate}

                        handleDeleteSaved={props.handleDeleteSaved}

                        setSearchData={(payload) => {
                            props.setSearchData(payload);
                            setLocalState({ forceRunSearch: true });
                        }}

                        FilterFields={props.FilterFields}
                        loadFilterFields={props.loadFilterFields}
                        mainFrameState={props.mainFrameState}

                        handleSavedResults={props.handleSavedResults}

                        location={props.location}
                        search={props.search}
                        filterVisible={props.mainFrameState.state.filterVisible}
                        searchData={{
                            data: state.data,
                            loading: state.loading,
                            totalCount: state.totalCount,
                        }}
                        projectId={props.projectId}
                        userProfile={props.userProfile}
                        setDefaultQuery={setDefaultQuery}
                    />

                    <MySearchTab  {...propsForSearchTab} />
                </div>
            </div>
            {state.isInsightModalOpen ? (
                <ModalWindow
                    open={true}
                    title={"Opening Insights"}
                    width={900}
                    okText="Ok"
                    cancelText="Cancel"
                    onOk={handleInsightOpenModalOnOk}
                    onCancel={handleInsightOpenModalOnClose}
                    isNonMaskable={true}
                    isNotClosable={true}
                >
                    <h5>{INSIGHT_OPENING_MESSAGE} </h5>
                </ModalWindow>
            ) : null}
        </div>);
};

const mapStateToProps = state => {
    const fromState = {
        ...state.Search,
        FilterFields: state.FilterFields,
        userProfile: state.Settings.profile,
        forceRunSearchFromRedux: state.Home.forceRunSearchFromRedux,
        selectedDocuments: state.Home.selectedDocuments,
        selectedTags: state.Home.selectedTags,
        userTags: state.Home.userTags,
        userProjectSettings: state.Settings?.userProjectSettings,
        projectId: state.Settings.profile?.currentProject ?? 0,
    };
    return fromState;
};

const mapDispatchToProps = (dispatch, props) => {
    return {

        saveListView: (data) => {
            const projectId = props.currentProject;
            dispatch(createSaveListViewTypeAction({ listViewType: data, projectId }));
        },
        handleDeleteSaved: (params, callBack) => dispatch(deleteSavedResults(params, callBack)),
        handleSavedSearchPaginate: (params) => dispatch(getSavedSearchList(params)),
        handleSavedResults: (params, callBack) => {
            dispatch(addSaveSearchResult(params, callBack));
        },
        loadFilterFields: () => dispatch(FilterFields()),
        handleGetDashboardPreview: (params, responceHandler) =>
            dispatch(getDashboardArticles(params, responceHandler)),
        ClearFilterFieldData: () => dispatch(ClearFilterFieldData()),
        setSearchData: (params) => {
            dispatch(setSearchData(params));
        },
        refreshComplete: () => dispatch(RefreshSearchComplete()),
        updateUserGroup: (params, callBack) => dispatch(updateUserGroupAction(params, callBack)),

    };
};

const MyHome = connect(mapStateToProps, mapDispatchToProps)(MyHomeComponent);
export default MyHome;