import style from "./style.module.scss";
import { MySearchContent } from "../SearchContent/MySearchContent";

export const MyRenderSearchContent = props=>{

    return (<div className={style.serachResult}>

        <MySearchContent
            tag={props.tag}
            setSearchData={props.setSearchData}
            search = {props.search} // from redux 
            dataLoadingTotalCountFromHomeState = {props.dataLoadingTotalCountFromHomeState}
            handleViewListClassName={props.handleViewListClassName}
            setFilterOnSavedResult={props.setFilterOnSavedResult}
            filterResults={props.filterResults}
            handleDeleteTags={props.handleDeleteTags}
            handleDeleteMainTag={props.handleDeleteMainTag}
            handleDeleteDate={props.handleDeleteDate}
            listViewType={props.listViewType}
            breadCrumbData={props.breadCrumbData}
            handleBreadCrumbArticles={props.handleBreadCrumbArticles}
            expandAllCard={props.expandAllCard}
            expandAllTags={props.expandAllTags}
            handleApplyFilterButtonClick={props.handleApplyFilterButtonClick}
            handleDeleteAllTags={props.handleDeleteAllTags}
            setSearchLocalData={props.setSearchLocalData}
        />
    </div>);
};