import { useDispatch } from "react-redux";
import { addTagModalToggle } from "Redux/Actions";
import { TabElement } from "Components/TabElement";
import TooltipContainer from "../../../../Components/Tooltip";
import { MyRenderSearchContent } from "./MyRenderSearchContent";
import ButtonElement from "Components/ButtonElement";
import { GridIconSection } from "../SearchControls/GridIconSection";
import { SelectionIconSection } from "../SearchControls/SelectionIconSection";
import style from "./style.module.scss";
import {
    ALL_ARTICLE_TAB,
    EXTERNAL_TAB,
    NEWS_TAB,
    EXPAND_ALL_TEXT,
    MINIMIZE_ALL,
    EXPAND_TAGS,
    COLLAPSE_TAGS,
} from "Constants/Home/Home.constants";
import { MyDisplayTagsModal } from "../../../../Components/SearchCard/MyDisplayTagsModal";
import { documentSelectionChangeAction } from '../../../../Redux/Reducers/Home/HomeState';

export const MySearchTab = props => {

    const propsForSearchContent = {
        breadCrumbData: props.breadCrumbData,

        dataLoadingTotalCountFromHomeState: props.dataLoadingTotalCountFromHomeState,
        setSearchData: props.setSearchData,
        handleBreadCrumbArticles: (event) => { props.handleBreadCrumbArticles(event); },
        filterResults: props.filterResults,
        handleDeleteTags: props.handleDeleteTags,
        handleDeleteMainTag: props.handleDeleteMainTag,
        handleDeleteDate: () => { },
        handleDeleteAllTags: props.handleDeleteAllTags,
        search: props.search,
        handleSearchPagination: () => { },
        listViewType: props.listViewType,
        handleViewListClassName: props.handleViewListClassName,
        handleFullContentData: () => { },
        addTagsHandler: () => { },
        tag: props.tag,
        dateFormat: '',
        tab: props.tab,
        expandAllCard: props.expandAllCard,
        expandAllTags: props.expandAllTags,
        handleExpandCardActive: () => { },
        handleApplyFilterButtonClick: props.handleApplyFilterButtonClick,
        setSearchLocalData: props.setSearchLocalData
    };


    const tabItems = [
        {
            key: ALL_ARTICLE_TAB,
            label: ALL_ARTICLE_TAB,
            disabled: props.dataLoadingTotalCountFromHomeState.loading && props.tab !== ALL_ARTICLE_TAB,
            children: <MyRenderSearchContent {...propsForSearchContent} />
        },
        {
            key: NEWS_TAB,
            label: NEWS_TAB,
            disabled: props.dataLoadingTotalCountFromHomeState.loading && props.tab !== NEWS_TAB,
            children: <MyRenderSearchContent {...propsForSearchContent} />
        },
        {
            key: EXTERNAL_TAB,
            label: EXTERNAL_TAB,
            disabled: props.dataLoadingTotalCountFromHomeState.loading && props.tab !== EXTERNAL_TAB,
            children: <MyRenderSearchContent {...propsForSearchContent} />
        }];

    const dispatch = useDispatch();
    const tagSelected = () => {
        const modalPayload = {
            visible: true,
            uids: props.selectedDocuments,
            allArticleList: props.dataLoadingTotalCountFromHomeState.data,
            setSearchLocalData: props.setSearchLocalData,
            userTags: props.selectedTags,
            append: true,
            clearSelection: true,
        };
        dispatch(addTagModalToggle(modalPayload));
    };
    const clearSelected = () => {
        const payload = {
            toClear: true
        }
        dispatch(documentSelectionChangeAction(payload));
    };
    const selectAllInPage = () => {
        const payload = {
            documentIds: props.dataLoadingTotalCountFromHomeState.data.map(d => d.DocumentId),
        }
        dispatch(documentSelectionChangeAction(payload));
    };

    const visible = props.tag?.modal?.visible;
    const disableTagSelected = (props.dataLoadingTotalCountFromHomeState.loading && props.tab !== ALL_ARTICLE_TAB) ||
        (props.selectedDocuments?.length === 0);
    const tooltipTagSelected = (props.selectedDocuments?.length === 0) && (props.selectedTags?.length === 0) ? "Please select some article and some tags first" :
        (props.selectedDocuments?.length === 0 ? "Please select some articles" :
            (props.selectedTags?.length === 0 ? "Please select some tags if you want to copy them" : ""));
    return (
        <>
            <TabElement
                default={props.tab}
                activeKey={props.tab}
                className="search-tab"
                onChange={props.onTabChange}
                items={tabItems}
                tabBarExtraContent={
                    <div className={style.tabRightBar}>
                        <div className={style.selectionSection}>
                            <div className={style.selectionIcons}>
                                <SelectionIconSection
                                    selectAllInPage={selectAllInPage}
                                    clearSelected={clearSelected}
                                    isSearchLoading={props.dataLoadingTotalCountFromHomeState.loading}
                                >
                                </SelectionIconSection>
                            </div>
                            <TooltipContainer title={tooltipTagSelected} placement="bottom">
                                <div className={style.selectionButtons}>
                                    <ButtonElement size="small" onClick={tagSelected} disabled={disableTagSelected}>
                                        Tag Selected
                                    </ButtonElement>
                                </div>
                            </TooltipContainer>
                        </div>
                        <ButtonElement size="small" onClick={props.handleExpandAllTags} disabled={props.dataLoadingTotalCountFromHomeState.loading}>
                            {props.expandAllTags ? COLLAPSE_TAGS : EXPAND_TAGS}
                        </ButtonElement>

                        <ButtonElement size="small" onClick={props.handleExpandAll} disabled={props.dataLoadingTotalCountFromHomeState.loading}>
                            {props.expandAllCard ? MINIMIZE_ALL : EXPAND_ALL_TEXT}
                        </ButtonElement>
                        <GridIconSection
                            handleListView={props.handleListView}
                            listViewType={props.listViewType}
                            isSearchLoading={props.dataLoadingTotalCountFromHomeState.loading}
                        />
                    </div>
                }
            />
            {visible && (<MyDisplayTagsModal />)}
        </>);
};