import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import TableElement from "../../Components/TableElement";
import { TABLE_COLUMNS } from "./TableColumns";
import { ContentLoader } from "../../Components/ContentLoader";
import Pagination from "../../Components/Pagination";
import ContentBody from "../../Components/ContentBody";

import globel from "../../styles/globel/style.module.scss";
import ErrorState from "../../Components/ErrorState";
import { DEFAULT_PAGE_COUNT } from "../../constants";
import ButtonElement from "../../Components/ButtonElement";
import { EditUserGroupModal } from "./TableActionModal/EditUserGroupModal";
import { DeleteUserGroupModal } from "./TableActionModal/DeleteUserGroupModal";
import style from "./style.module.scss";
import { addUserGroupAction, getUserGroupAction, updateUserGroupAction, deleteUserGroupAction } from "../../Redux/Actions/UserGroups";
import { getSavedSearchesAction } from "../../Redux/Actions/Search";
import { validateUserGroup } from "./helper"
import { notify } from '../../Components/Notification';

const UserGroups = props => {

  const initGroup = { name: undefined, defaultQueryId: undefined, note: undefined };
  const initState = {
    sortedInfo: { columnKey: 'id', field: 'id', order: 'asc' },
    addModalVisible: false,
    updateModalVisible: false,
    deleteModalVisible: false,
    pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT },
    userGroups: [],
    totalCount: 0,
    group: initGroup,
    projectId: 0,
    savedSearches: [],
  };

  const [state, setLocalState] = useState(initState);

  const setState = (data) => {
    setLocalState(prevState => {
      return { ...prevState, ...data };
    });
  };


  useEffect(() => {
    setState({ userGroups: props.userGroups });
  }, [props.userGroups]);

  useEffect(() => {
    props.getSavedSearches();
  }, [props.savedSearchesModified]);

  useEffect(() => {
    setState({ totalCount: props.totalCount });
  }, [props.totalCount]);

  useEffect(async () => {
    setState({ projectId: props.projectId || state.projectId });
    setState({ savedSearches: props.savedSearches?.length ?? 0 > 0 ? props.savedSearches : state.savedSearches });
    const payload = getPayload({ projectId: props.projectId });
    await props.getUserGroup(payload);
  }, [state.pagination, state.sortedInfo, props.projectId, props.savedSearches]);

  const setAddModalVisibility = (visible) => {
    setState({ addModalVisible: visible });
  };
  const setUpdateModalVisibility = (visible) => {
    setState({ updateModalVisible: visible });
  };
  const setDeleteModalVisibility = (visible) => {
    setState({ deleteModalVisible: visible });
  };

  const onDetailsChange = (field, value) => {
    const json = {};
    json[field] = value;
    setState({ group: { ...state.group, ...json } });
  };

  const handleAdd = () => {
    setState({ group: initGroup });
    setAddModalVisibility(true);
  }

  const cancelAdd = () => {
    setAddModalVisibility(false);
  };

  const onAddSubmit = () => {
    const group = {...state.group, name: state.group.name.trim()};
    const validation = validateUserGroup(group);
    if (!validation.valid) {
      notify.error(validation.message)
      return;
    }

    props.addUserGroup({ ...group, projectId: state.projectId }, () => {
      setAddModalVisibility(false);
      props.getUserGroup(getPayload());
    });
  };

  const handleUpdate = (record) => {
    setState({ group: record });
    setUpdateModalVisibility(true);
  };

  const cancelUpdate = (record) => {
    setUpdateModalVisibility(false);
  };

  const onUpdateSubmit = () => {
    const group = {...state.group, name: state.group.name.trim()};
    const validation = validateUserGroup(group);
    if (!validation.valid) {
      notify.error(validation.message)
      return;
    }

    props.updateUserGroup({ ...group, projectId: state.projectId }, () => {
      setUpdateModalVisibility(false);
      props.getUserGroup(getPayload());
    });
  };

  const handleDelete = (record) => {
    setState({ group: record });
    setDeleteModalVisibility(true);
  };

  const cancelDelete = (record) => {
    setDeleteModalVisibility(false);
  };

  const onConfirmDelete = () => {
    props.deleteUserGroup(state.group, () => {
      setDeleteModalVisibility(false);
      props.getUserGroup(getPayload());
    });
  };

  const handleSortingChange = (_, __, sorter) => {
    const sortedInfo = {
      columnKey: sorter.columnKey,
      field: sorter.field,
      order: sorter.order == 'descend' ? 'desc' : 'asc',
    };
    setState({ sortedInfo: sortedInfo });
  };

  const getPayload = (parms) => {
    const { pagination, sortedInfo, projectId } = state;
    const data = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      sortField: sortedInfo.field,
      sortOrder: sortedInfo.order,
      projectId: projectId || null,

      ...parms,
    };
    return data;
  };

  const handlePagination = (pageNumber, pageSize) => {
    setState({ pagination: { pageNumber: pageNumber, pageSize: pageSize } });
  };

  const GroupPagination = () => {
    return (
      <Pagination
        pageSize={state.pagination?.pageSize}
        current={state.pagination?.pageNumber}
        total={state.totalCount}
        onChange={handlePagination}
        showSizeChanger={true}
        pageSizeOptions={["10", "20", "50", "100"]}
      />
    );
  };

  return (
    <>
      <ContentBody>
        {props.error ? (
          <ErrorState
            customHeight={"600"}
            content="ERROR"
            type={"errorImage"}
            errorMessage={props.errorMessage}
          />
        ) : (
          <>
            <div className={globel.paginationWrapper}>
              <div className={style.addButtonWrapper}>
                <ButtonElement
                  size={"small"}
                  onClick={handleAdd}
                  children={"Add Group"}
                />
              </div>
              <EditUserGroupModal
                title={"Add User Group"}
                buttonTitle={"Add"}
                onCancel={cancelAdd}
                visible={state.addModalVisible}
                onChange={onDetailsChange}
                onSubmit={onAddSubmit}
                loader={props.addUserLoading}
                group={state.group}
                savedSearches={state.savedSearches}
                defaultSearchVisible={false}
              />
            </div>
            <div className={globel.paginationWrapper}>
              <div className={globel.searchresultCount}>
                <span>
                  {state.userGroups.length > 0
                    ? "Showing " +
                    state.userGroups.length +
                    " out of " +
                    state.totalCount +
                    " Results"
                    : ""}
                </span>
              </div>
              <div className={globel.searchresultPage}>
                {state.totalCount > DEFAULT_PAGE_COUNT ? GroupPagination() : ""}
              </div>
            </div>
            <TableElement
              columns={TABLE_COLUMNS.COLUMNS({
                sortedInfo: state.sortedInfo,
                handleUpdate: handleUpdate,
                handleDelete: handleDelete,
              })}
              data={props.userGroups.map((item, key) => ({
                ...item,
                defaultSearch: state.savedSearches?.find(s => s.id === item.defaultQueryId)?.searchKeyword,
                key,
              }))}
              onChange={handleSortingChange}
              scrollY={"calc(100vh - 270px)"}
              loading={props.userGroupsloading}
              resourceName="UserGroups"
              emptyText="No User Group"
            />
          </>

        )}
      </ContentBody>
      <EditUserGroupModal
        title={"Update Group"}
        buttonTitle={"Update"}
        onCancel={cancelUpdate}
        visible={state.updateModalVisible}
        onChange={onDetailsChange}
        onSubmit={onUpdateSubmit}
        group={state.group}
        loader={props.updateUserLoading}
        savedSearches={state.savedSearches}
        defaultSearchVisible={true}
      />
      <DeleteUserGroupModal
        visible={state.deleteModalVisible}
        onCancel={cancelDelete}
        confirmDelete={onConfirmDelete}
        loader={props.deleteUserLoading}
        group={state.group}
      />
    </>
  );
};

const mapStateToProps = state => {
  const fromState = {
    ...state.UserGroups,
    projectId: state.Settings.profile?.currentProject ?? 0,
    savedSearches: state.Search.savedSearches,
    savedSearchesModified: state.Search.savedSearchesModified,
  };
  return fromState;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUserGroup: (payload, callback) => {
      dispatch(addUserGroupAction(payload, callback));
    },
    getUserGroup: (payload, callback) => {
      dispatch(getUserGroupAction(payload, callback));
    },
    updateUserGroup: (payload, callback) => {
      dispatch(updateUserGroupAction(payload, callback));
    },
    deleteUserGroup: (payload, callback) => {
      dispatch(deleteUserGroupAction(payload, callback));
    },
    getSavedSearches: (payload, callback) => {
      dispatch(getSavedSearchesAction(payload, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
